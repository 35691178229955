<template>
  <div class="show-house-table box-shadow-default">
    <el-form ref="form" :label-position="labelPosition" :model="params" label-width="110px" size="small">
      <el-row :gutter="40">
        <el-col :span="22">
          <el-form-item label="不动产坐落" :rules="[validate.required]">
            <zl-directives v-model="zlData" :zlConfig="zlConfig"></zl-directives>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :span="8">
          <el-form-item label="宗地代码" prop="zddm" :rules="[validate.required]">
            <el-select v-model="params.zddm" placeholder="请选择宗地代码">
              <el-option v-for="(item, i) in zdOptions" :label="item.zddm" :value="item.zddm" :key="i"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-form-item label="坐落">
            <el-input disabled v-model="selectedZl"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <!--去掉自然幢号查询 -->
        <!-- <el-col :span="12">
          <el-form-item label="自然幢号"
                        prop="zrzh"
                        :rules="zrzhQrule">
            <el-row>
              <el-col :span="10">
                <el-input v-model="zrzhQ"
                          disabled></el-input>
              </el-col>
              <el-col :span="1">
                <el-divider></el-divider>
              </el-col>
              <el-col :span="4">
                <el-input v-model="params.zrzh"></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col> -->
        <el-col :span="8">
          <el-form-item prop="zrzh" label="自然幢号" :rules="zrzhQrule">
            <el-select v-model="params.zrzh" placeholder="请选择自然幢号" @change="zrzhChange">
              <el-option v-for="item in zrzhOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="0">
            <el-input v-model="xmmc" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-button :loading="loading" type="primary" @click="searchHouseTable">查询</el-button>
        </el-col>
      </el-row>
      <!-- <div class="text-center">
        <el-button :loading="loading" type="primary" @click="searchHouseTable">查询</el-button>
      </div> -->
    </el-form>
    <Lpb
      ref="lpb"
      :legend-data="legendData"
      :details-checkbox="detailsCheckbox"
      :arrData="arrData"
      :showlpb="showlpb"
      @nextBtnState="nextBtnState"
      @selectedHouse="selectedHouse"
    />
    <div class="text-center main-footer">
      <el-button type="primary" @click="prev">上一步</el-button>
      <el-button type="primary" @click="next" :disabled="isShow">下一步</el-button>
    </div>
  </div>
</template>

<script>
import Lpb from './lpb/Lpb';
import validate from '@/utils/validate';
import getlpbInfo from '@/pages/iebdc/api/apply/applicant-lpb';
import applicantSaveService from '@/pages/iebdc/api/apply/applicant-save';
import { localDataUser } from '@/pages/iebdc/utils/local-data';
import { mapState } from 'vuex';
export default {
  name: 'showHouseTable',
  components: {
    Lpb,
  },
  data() {
    return {
      loading: false,
      //图例
      legendData: [
        {
          name: '查封',
          color: '#FF6666',
          key: 'SFCF',
        },
        {
          name: '预查封',
          color: '#FFA459',
          key: 'SFYCF',
        },
        {
          name: '异议',
          color: '#FFCC33',
          key: 'SFYY',
        },
        {
          name: '抵押',
          color: '#3EA1F4',
          key: 'SFDYA',
        },
        {
          name: '地役',
          color: '#00C1CE',
          key: 'SFDYI',
        },
        {
          name: '预告',
          color: '#A2A2DA',
          key: 'SFYG1SFYGDY',
        },
        {
          name: '预抵押',
          color: '#f959ff',
          key: 'SFYGDY',
        },
        {
          name: '所有权',
          color: '#34CA85',
          key: 'SFBDC',
        },
      ],
      //详情-换个名字
      detailsCheckbox: {
        name: '',
        value: [],
        list: [
          { name: '建筑面积', val: '建筑面积' },
          { name: '套内面积', val: '套内面积' },
          { name: '房屋用途', val: '房屋用途' },
        ],
      },
      //假数据-住宅区和非住宅区数据
      // arrData: lpbData,
      arrData: [], //所有楼盘表数据
      showlpb: false,
      //区域代码
      zlData: {
        zlProvince: '',
        zlCity: '',
        zlArea: '',
      },
      zrzhQ: '',
      // zrzhQrule: [validate.required, { min: 4, max: 4, message: '长度为4个字符', trigger: 'blur' }],
      zrzhQrule: [validate.required, { message: '请选择自然幢号', trigger: 'change' }],
      params: {
        zddm: '', //宗地代码
        zrzh: '', //自然幢号
        lcdm: '', //流程代码
        lcxlmc: '', //流程小类名称
      },
      // zdOptions: [], //宗地列表
      selectedZl: '', // 选中宗地代码对应坐落
      zlConfig: {
        zlKey: 'code',
        rules: [],
        hideZl: true,
        disabled: false,
      },
      validate: validate,
      labelPosition: 'right',
      isShow: true, //是否显示下一步
      zrzhOptions: [], // 自然幢号列表
      xmmc: '', // 自然幢号项目名称
    };
  },
  computed: {
    // 选择的流程类型信息
    ...mapState('wysq-data', ['firstFlow']),
    ...mapState('wysq-step', ['nextItem', 'prevItem']),
    // 选择的机构信息
    getSelectedOrgInfo() {
      return this.$store.getters['dashboard/getSelectedOrgInfo'];
    },
    zdOptions() {
      let result = [];
      let allZddm = localDataUser.get().zdxxes;
      (allZddm || []).forEach((item) => {
        if (item.zddm.indexOf(this.zlData.zlArea) >= 0) {
          result.push(item);
        }
      });
      return result;
    },
  },
  watch: {
    'params.zddm': function (val) {
      // if (val) {
      //   this.zrzhQ = val + 'F';
      // } else {
      //   this.zrzhQ = '';
      // }
      this.selectedZl = '';
      this.zdOptions.map((item) => {
        if (item.zddm === val) {
          this.$nextTick(() => {
            this.selectedZl = item.zl;
          });
        }
      });
      // 通过宗地代码获取所有自然幢号
      getlpbInfo
        .getFwxxByZddm({ zddm: this.params.zddm, qydm: this.zlData.zlArea, lcdm: this.params.lcdm })
        .then((res) => {
          if (res.success && res.data) {
            const arr = [...res.data.fwxx];
            this.zrzhOptions = arr.map((item) => {
              return {
                value: item.zrzh,
                label: item.zrzh,
                xmmc: item.xmmc,
              };
            });
            this.zrzhOptions.sort((a, b) => {
              return parseInt(a.value.slice(-4)) - parseInt(b.value.slice(-4));
            });
            this.params.zrzh = this.zrzhOptions[0].value;
            this.xmmc = this.zrzhOptions[0].xmmc;
          }
        });
    },
    // 切换行政区时置空已选的宗地代码
    zlData: {
      handler() {
        this.params.zddm = '';
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    searchHouseTable() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          const params = {
            qydm: this.zlData.zlArea,
            zddm: this.params.zddm,
            // zrzh: this.zrzhQ + this.params.zrzh,
            zrzh: this.params.zrzh,
            lcdm: this.params.lcdm,
            lcxlmc: this.params.lcxlmc,
          };
          const a = [];
          a.push(params);
          // 提取楼盘表
          this.showlpb = true;
          getlpbInfo
            .getlpbInfo({ params: a })
            .then((res) => {
              if (res.success && res.data) {
                if (res.data.fwxx.length < 1) {
                  this.$message.warning('楼盘表无数据！！！');
                  this.arrData = [];
                  this.$nextTick(() => {
                    this.isShow = false;
                    this.$refs.lpb.init(1);
                  });
                } else {
                  this.arrData = [...res.data.fwxx];
                  this.$nextTick(() => {
                    this.showlpb = true;
                    this.$refs.lpb.init(2);
                  });
                }
              }
              this.loading = false;
            })
            .catch((errMsg) => {
              // this.isShow = true;
              this.$message.error(errMsg);
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 上一步
    prev() {
      this.$router.push({
        name: this.prevItem.name,
        query: 1,
      });
    },
    // 下一步
    next() {
      this.$refs.lpb.selectedHouse();
    },
    selectedHouse(selectedHouseSum) {
      // 接口对接
      selectedHouseSum.map((item) => {
        const updateHouse = {
          jzmj: typeof item.jzmj === 'number' ? item.jzmj.toString() : item.jzmj,
          tnjzmj: typeof item.tnjzmj === 'number' ? item.tnjzmj.toString() : item.tnjzmj,
          ftjzmj: typeof item.ftjzmj === 'number' ? item.ftjzmj.toString() : item.ftjzmj,
          fttdmj: typeof item.fttdmj === 'number' ? item.fttdmj.toString() : item.fttdmj,
          dytdmj: typeof item.dytdmj === 'number' ? item.dytdmj.toString() : item.dytdmj,
          zh:
            !item.zh && typeof item.zh !== 'undefined' && item.zh !== 0
              ? item.bdcdyh.substring(20, 24).replace(/\b(0+)/gi, '')
              : item.zh,
        };
        Object.assign(item, updateHouse);
      });
      if (selectedHouseSum.length) {
        let flowname = '';
        if (this.firstFlow.thirdTypeData.flowname) {
          flowname = this.firstFlow.thirdTypeData.flowname;
        } else {
          flowname = this.firstFlow.thirdTypeData.name;
        }
        //存勾选的所有户数据
        const params = {
          sfyddbl: 0,
          userName: localDataUser.get().realName,
          lcdm: this.firstFlow.thirdTypeData.netFlowdefCode,
          userId: localDataUser.get().id, // 用户id
          sqrlx: localDataUser.get().userCategory, // 申请人类型
          bdclb: this.firstFlow.secondTypeData.bdclbEnum, // 不动产类别
          flowcode: this.firstFlow.thirdTypeData.flowCode,
          netFlowdefCode: this.firstFlow.thirdTypeData.netFlowdefCode,
          flowname: flowname,
          djdl: this.firstFlow.thirdTypeData.djdl,
          qllx: this.firstFlow.thirdTypeData.qllx,
          subFlowcode: this.firstFlow.fourTypeData.code,
          subFlowname: this.firstFlow.fourTypeData.name,
          taskCode: this.firstFlow.thirdTypeData.taskCode,
          taskHandleItem: this.firstFlow.thirdTypeData.taskHandleItem,
          pjlcmc: this.firstFlow.thirdTypeData.pjlcmc,
          qlxxExMhs: selectedHouseSum,
          ...this.getSelectedOrgInfo,
        };
        //保存提取的业务信息生成新的业务号
        applicantSaveService.saveSqxx(params).then((res) => {
          if (res.success) {
            this.$store.commit('wysq-step/targetToStep', '楼盘表提取');
            // 路由跳转
            this.$router.push({
              name: this.nextItem.name,
              params: {
                ywh: res.data.wwywh,
              },
            });
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.$message.error('请先选择户！！！');
      }
    },
    //下一步的状态
    nextBtnState(state) {
      this.isShow = state;
    },
    // 自然幢号右侧显示项目名称
    zrzhChange(val) {
      console.log(val);
      this.zrzhOptions.forEach((item) => {
        if (item.value === val) {
          this.xmmc = item.xmmc;
        }
      });
    },
  },
  mounted() {
    this.$store.commit('wysq-step/targetToStep', '楼盘表提取');
    this.params.lcdm = this.firstFlow.thirdTypeData.netFlowdefCode;
    this.params.lcxlmc = this.firstFlow.thirdTypeData.name;
    // console.log(this.firstFlow);
    // this.zdOptions = [...localDataUser.get().zdxxes];
    // console.log(IEBDC_USER_DATA, '取到宗地代码信息');
  },
};
</script>

<style scoped lang="scss">
@import 'src/pages/iebdc/styles/public';
@import 'src/pages/iebdc/styles/common-variables';
.show-house-table {
  padding: $spacing-large;
  /deep/ .el-select {
    width: 100%;
  }
  .main-footer {
    margin-top: $spacing-large;
  }
}
</style>
